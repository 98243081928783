import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Home.vue')
    },
    {
        path: "/users",
        name: 'Users',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Users.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Roles.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/logs",
        name: 'Logs',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Logs.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/modules",
        name: 'Modules',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Modules.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/pages",
        name: 'Pages',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Pages.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: "/claims",
        name: 'Claims',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Claims.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/historic",
        name: 'Historic',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Historic.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/client",
        name: 'Client',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Client.vue'),
        meta: {
            authRequired: true
        }
    },


];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});






export default router;
