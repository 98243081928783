import axios from "axios";
import store from "../store";
import router from '../routes';

const URL = process.env.VUE_APP_API;

const API = function (parameters){
    const http = axios.post(URL, parameters);
    return http; 
}

class APIs {
    constructor(module=null,funct=null) {
        this.module =   module;
        this.function =  funct;
    }
    Status() {
        store.state.loading = true;
        return API({
            module:     this.module
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
    }
    Ini() {
        store.state.loading = true;
        return API({
            module:     this.module,
            function:    this.function,
            token:      localStorage.getItem('Token'),
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
	}
    Process(cas,array) {
        store.state.loading = true;
        return API({
            module:     this.module,
            function:    this.function,
            token:      localStorage.getItem('Token'),
            case:       cas,
            array:      array
        }).then(res => res.data).catch(error => { 
            this.ErrorConexion(error);
        });
    }
    ErrorConexion(error) {
        console.log(error);
        store.state.loading = false;
        /*
        store.state.MenuActive = true;
        store.state.view.conexion = true;
        store.state.authenticated = false;
        store.state.usuario = null;
        store.state.correo = null;
        store.state.valido = false;
        store.state.admin = false;
        store.state.paginas = [];
        store.state.menu = [];
        store.state.menuexterno = [];
        
        */
        router.push({ path: '/' });  
    }
    Mens(status,leng,message=null) {
        let result = {
            severity: null, 
            summary: null, 
            detail: null, 
            life: 3000
        };
        if (leng=='ja'){
            result.summary = 'メッセージ';
        }
        if (leng=='es'){
            result.summary = 'Mensaje';
        }
        if (leng=='en'){
            result.summary = 'Message';
        }
        if(status){
            result.severity = 'success';
            if (leng=='ja'){
                result.detail = '処理される情報';
            }
            if (leng=='es'){
                result.detail = 'Información procesada';
            }
            if (leng=='en'){
                result.detail = 'Information processed';
            }
        } else {
            result.severity = 'warn';
            if (leng=='ja'){
                result.detail = '情報が処理されていない';
            }
            if (leng=='es'){
                result.detail = 'Información no procesada';
            }
            if (leng=='en'){
                result.detail = 'Information not processed ';
            }
        }
        if(message){
            result.detail = message;
        }
        return result;
    }

   
}

export default APIs;