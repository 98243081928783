<template>
	<div class="layout-footer">
		<div class="footer-copy">
            <p>Copyright © International Insureance N.V. 2023</p>
        </div>
		<span class="footer-text">
			<div class="footer-controls">
                <!--
                <button class="p-link movil" style="text-align: center;">
                    <span class="layout-top-icon pi pi-comment"></span><br>
                    <span class="ocultar">{{$t('FAQ')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;">
                    <span class="layout-top-icon pi pi-file"></span><br>
                    <span class="ocultar">{{$t('Documents')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;">
                    <span class="layout-top-icon pi pi-list"></span><br>
                    <span class="ocultar">{{$t('Terms')}}</span>
                </button>
                <button class="p-link movil" style="text-align: center;">
                    <span class="layout-top-icon pi pi-key"></span><br>
                    <span class="ocultar">{{$t('Privacy Policy')}}</span>
                </button>
            -->
            </div>
		</span>
		<span class="footer-text" style="right: 5px">
			<i class="pi pi-check-circle p-mr-2 p-mb-2" style="fontSize: 1.5rem" v-if="this.$store.state.status"></i>
            <i class="pi pi-exclamation-circle p-mr-2 p-mb-2" style="fontSize: 1.5rem" v-else></i>
			<i class="pi pi-mobile p-mr-2 p-mb-2" style="fontSize: 1.5rem" v-if="this.$store.state.mobile"></i>
			<i class="pi pi-desktop p-mr-2 p-mb-2" style="fontSize: 1.5rem" v-else></i>
		</span>
	</div>
</template>

<style>



</style>