import { createStore } from 'vuex'
import API from "../service/API";
import router from '../routes';
//import { useI18nPlugin } from '@unify/vuex-i18n';
import Detector from 'detector-js';

export default createStore({
  state: {
    loading: false,
    authenticated: false,
    MenuActive: true,
    MenuActiveMobile: false,
    display: {
      login: true,
      register: false
    },
    passw: null,
    valido: false,
    url: 'https://zelfium.com/#/result/',
    photo: 'images/foto.jpg',
    fecha: null,
    fechaini: null,
    email: null,
    courtesy: false,
    user: 0,
    name: null,
    customer: null,
    rol: 0,
    currency: null,
    verificacion: false,
    status: false,
    mobile: false,
    error: null,
    lenguaje: 'en',
    platform: null,
    products: [],
    prices: [],
    title: {es: null, en: null, icon: null, to: null},
    menu: {
      es: [],
      en: [],
    },
    tras: [],
    pages: [],
    actualizar: 0,
    person: {},
    busines: {},
    income: {},
  },
  mutations: {
    Loading(){
      this.state.loading = false;	
    },
    Status(){
      const detector = new Detector();
      this.state.platform = {
        browser: detector.browser.name,
        os: detector.os.name,
        cpu: detector.cpu.platform,
        platform: detector.platform,
      };
      const Consult = new API();
      Consult.Status().then(response => {
        //this.state.error = response;
        this.state.fecha = response.fecha;
        this.state.status = response.status;
        this.state.loading = false;	
      });   
    },
    Login(state,valores){
      this.state.authenticated = true;
      localStorage.setItem('Token', valores.token);
      this.state.id = valores.id;
      if(valores.photo){this.state.photo = valores.photo;}
      this.state.user = valores.id;   
      this.state.name = valores.name;  
      this.state.customer = valores.customer;  
      this.state.courtesy = valores.courtesy;  
      this.state.email = valores.email;
      this.state.MenuActive = true;
      if(this.state.mobile){
        this.state.MenuActiveMobile = true;
      }

    },
    Menu(state,valores){
      this.state.pages = valores.pages;
      this.state.menu = {
        en: valores.external.en.concat(valores.internal.en),
        es: valores.external.es.concat(valores.internal.es),
      };

    },
    Ini(){
      var ini = this.state.menu.en[0].to;
      switch (ini){
        case '/dashboard':
          router.push({ path: '/dashboard' });
          break;
        case '/mytest':
          router.push({ path: '/mytest' });
          break;
        default:
          router.push({ path: '/' });
      }
    },
    Validate(state,page){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      let pag = this.state.pages.filter(val => val.to == page.path);
      if(pag.length){
        this.state.title = pag[0];
      } else {
        router.push({ path: '/' });
      }
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        && screen.width<1024) {
        this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Profile(){
      router.push({ path: '/myprofile' });
    },
    Verificado(state,valor){
      this.state.verificacion = valor.estado;
    },
    Logout(){
      const Consult = new API('Security','Exit');
      Consult.Ini();
      localStorage.removeItem('Token');
      this.state.MenuActive = true;
      this.state.authenticated = false;
      this.state.token = null;
      this.state.loading = false;
      this.state.user = 0;   
      this.state.email = null;
      this.state.pages = [];
      this.state.photo = 'images/foto.jpg';
      this.state.display.login = true;
      router.push({ path: '/' });
    },
  },
  actions: {
  },
  modules: {
  }
})
